<template>
  <page-wrapper
      :isLoading="isLoading"
      :hasFilters="true"
      @submitFilters="GetAllTransactions"
  >
    <template #title> Transaction List</template>
    <template #titleBtn>
      <b-button
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          class=""
          variant="success"
          @click="downloadFile"
      >
        Download Excel File
      </b-button>
    </template>
    <template #filters>
      <b-form-group label="Status" label-for="status">
        <v-select
            class="mr-md-2 w-auto"
            id="status"
            v-model="transactionFilters.status"
            :options="statusOptions"
            :clearable="false"
            :reduce="(val) => val.value"
        />
      </b-form-group>
      <b-form-group label="State" label-for="state">
        <v-select
            class="mr-md-2 w-auto"
            id="state"
            v-model="transactionFilters.completed"
            :options="completedOptions"
            :clearable="false"
            :reduce="(val) => val.value"
        />
      </b-form-group>
      <b-form-group label="Count" label-for="count">
        <v-select
            class="mr-md-2 w-auto"
            id="count"
            v-model="transactionFilters.count"
            :options="perPageOptions"
            :clearable="false"
        />
      </b-form-group>
    </template>
    <custom-table
        :pageNumber="transactionFilters.pageNumber"
        :count="transactionFilters.count"
        :totalCount="totalCount"
        :tableItems="transactions"
        :tableColumns="myTableColumns"
        @changePage="changePage"
    >
      <template #userId="data">
        <p>{{ data.item.userInfo.userName }}</p>
      </template>
      <template #status="data">
        <b-badge :variant="data.item.status == 1 ? 'warning' : 'success'">
          {{ data.item.status == 1 ? "Requested" : "Done" }}
        </b-badge>
      </template>
      <template #completed="data">
        <p :class="data.item.completed ? 'text-success' : 'text-danger'">
          {{ data.item.completed ? "Completed" : "Not Completed" }}
        </p>
      </template>
      <template #createDate="data">
        {{ data.item.createDate.slice(0, 10) }}
        -
        {{ data.item.createDate.slice(11, 19) }}
      </template>
      <template #amount="data">
        <p>{{ parseFloat(data.item.amount) }} $</p>
      </template>
      <template #submit="data">
        <span v-if="data.item.status != 2" class="cursor-pointer">
          <feather-icon
              @click="setSelectedItems(data.item)"
              icon="CheckSquareIcon"
              size="20"
              class="text-primary"
          />
        </span>
        <span v-else>-</span>
      </template>
      <template #cancel="data">
        <span v-if="data.item.status == 1" class="cursor-pointer">
          <feather-icon
              @click="setSelectedItemsToCancel(data.item)"
              icon="XCircleIcon"
              size="20"
              class="text-danger"
          />
        </span>
        <span v-else>-</span>
      </template>
    </custom-table>
    <b-modal
        id="blockchainsModal"
        centered
        cancelTitle="Close"
        size="lg"
        title="Select blockchain"
        ok-title="Submit Transfer"
        @ok="submitTransfer"
    >
      <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="blockchains"
          responsive
          :fields="blockchainsTableColumns"
          primary-key="id"
          show-empty
          bordered
          striped
          empty-text="Nothing found !"
      >
        <template #cell(active)="data">
          <p class="text-success" v-if="data.item.active === true">Active</p>
          <p class="text-danger" v-if="data.item.active === false">
            Not Active
          </p>
        </template>

        <template #cell(hasTron)="data">
          <p class="text-success" v-if="data.item.hasTron === true">Has TRON</p>
          <p class="text-danger" v-if="data.item.hasTron === false">No Trons</p>
        </template>

        <template #cell(createDate)="data">
          {{ data.item.createDate.slice(0, 10) }}
          -
          {{ data.item.createDate.slice(11, 19) }}
        </template>

        <template #cell(actions)="data">
          <b-form-radio
              v-model="selectedBlockchainId"
              name="some-radios"
              :value="data.item.blockchainId"
          >
          </b-form-radio>
        </template>
      </b-table>
    </b-modal>

    <b-modal
        id="modalCancel"
        centered
        ok-title="Yes"
        cancelTitle="No"
        @ok="cancelTransaction"
    >
      <span>Do you want to cancel it?</span>
    </b-modal>
  </page-wrapper>
</template>

<script>
import {BBadge, BButton, BFormGroup, BFormRadio, BModal, BTable,} from "bootstrap-vue";
import vSelect from "vue-select";
import {TransactionCancelWithdrawRequest, TransactionGetAllRequest,} from "@/libs/Api/Transaction";
import {WalletTransferToBlockchainRequest} from "@/libs/Api/Wallet";
import {BlockchainGetAllRequest} from "@/libs/Api/BlockChain";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {getUserData} from "@/auth/utils";
import axiosIns from "@/libs/axios";
import {saveAs} from "file-saver";
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";

export default {
  title: "Transactions List",
  name: "TransactionList",
  data() {
    return {
      transactions: null,
      transactionFilters: {
        pageNumber: 1,
        count: 10,
        status: null,
        completed: null,
      },
      totalCount: null,
      blockchains: null,
      isLoading: false,
      perPageOptions: [10, 20, 30, 40, 50],
      statusOptions: [
        {label: "ALL", value: null},
        {label: "Requested", value: 1},
        {label: "Done", value: 2},
      ],
      completedOptions: [
        {label: "ALL", value: null},
        {label: "Completed", value: true},
        {label: "Not Completed", value: false},
      ],
      myTableColumns: [
        {
          key: "transactionId",
          label: "Transaction id",
        },
        {
          key: "userId",
          label: "Username",
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "completed",
          label: "State",
        },
        {
          key: "recipientAddress",
          label: "Recipient Address",
        },
        {
          key: "createDate",
          label: "Date",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "submit",
          label: "Submit transfer",
        },
        {
          key: "cancel",
          label: "Cancel transfer",
        },
      ],
      blockchainsTableColumns: [
        {
          key: "blockchainId",
          label: "Blockchain id",
        },
        {
          key: "userId",
          label: "User Id",
        },
        {
          key: "walletAddress",
          label: "Address",
        },
        {
          key: "createDate",
          label: "Date",
        },
        {
          key: "active",
          label: "Active State",
        },
        {
          key: "hasTron",
          label: "TRON State",
        },
        {
          key: "actions",
          label: "Select",
        },
      ],
      recipientAddress: null,
      transactionId: null,
      selectedBlockchainId: null,
    };
  },
  async created() {
    await this.GetAllTransactions();
    await this.GetAllBlockchains();
  },
  watch: {
    status: function () {
      this.GetAllTransactions();
    },
    completed: function () {
      this.GetAllTransactions();
    },
    currentPage: function () {
      this.GetAllTransactions();
    },
    perPage: function () {
      this.GetAllTransactions();
    },
  },
  methods: {
    changePage(pageNumber) {
      this.transactionFilters.pageNumber = pageNumber;
      this.GetAllTransactions();
    },
    async GetAllTransactions() {
      try {
        this.isLoading = true;
        let _this = this;
        let transactionGetAllRequest = new TransactionGetAllRequest(_this);

        transactionGetAllRequest.setParams(_this.transactionFilters);
        await transactionGetAllRequest.fetch(
            function (content) {
              _this.transactions = content.transactions;
              _this.totalCount = content.transactionsCount;
            },
            function (error) {
              console.log(error);
            }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async GetAllBlockchains() {
      try {
        this.isLoading = true;
        let _this = this;
        let blockchainGetAllRequest = new BlockchainGetAllRequest(_this);
        let data = {
          pageNumber: 1,
          count: 60,
          hasTron: true,
          active: true,
        };
        blockchainGetAllRequest.setParams(data);
        await blockchainGetAllRequest.fetch(
            function (content) {
              _this.blockchains = content.blockchains;
            },
            function (error) {
              console.log(error);
            }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async submitTransfer() {
      try {
        this.isLoading = true;
        let _this = this;
        if (_this.selectedBlockchainId === null) {
          return _this.$toast({
            component: ToastificationContent,
            position: "bottom-center",
            props: {
              title: `Failed`,
              icon: "CloseIcon",
              variant: "danger",
              text: `Please select a blockchain first`,
            },
          });
        }
        let data = {
          reciverAddress: _this.recipientAddress,
          blockchainId: _this.selectedBlockchainId,
          transactionId: _this.transactionId,
        };

        let walletTransferToBlockchainRequest =
            new WalletTransferToBlockchainRequest(_this);
        walletTransferToBlockchainRequest.setParams(data);
        await walletTransferToBlockchainRequest.fetch(
            function (content) {
              window.location.reload();
            },
            function (error) {
              console.log(error);
            }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async cancelTransaction() {
      try {
        this.isLoading = true;
        let _this = this;
        let transactionCancelWithdrawRequest =
            new TransactionCancelWithdrawRequest(_this);
        let data = {
          transactionId: _this.transactionId,
        };
        transactionCancelWithdrawRequest.setParams(data);
        await transactionCancelWithdrawRequest.fetch(
            function (content) {
              window.location.reload();
            },
            function (error) {
              console.log(error);
            }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    setSelectedItems(param) {
      this.recipientAddress = param.recipientAddress;
      this.transactionId = param.transactionId;
      this.$bvModal.show("blockchainsModal");
    },
    async downloadFile() {
      try {
        this.isLoading = true;
        let _this = this;
        let userToken = getUserData().data.token;

        await axiosIns
            .post(`Transaction/GetAllExportExcel`, null, {
              responseType: "blob",
              Authorization: userToken,
              params: {
                status: _this.transactionFilters.status,
                completed: _this.transactionFilters.completed
              }
            })
            .then((resp) => {
              saveAs(resp.data, "transactions.xlsx");
            });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    setSelectedItemsToCancel(param) {
      this.transactionId = param.transactionId;
      this.$bvModal.show("modalCancel");
    },
  },
  components: {
    BFormGroup,
    BButton,
    BTable,
    BBadge,
    BModal,
    vSelect,
    BFormRadio,
    PageWrapper,
    CustomTable,
  },
};
</script>

<style scoped></style>
